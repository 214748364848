import { createApp } from "vue";
import Main from "./vueComponent/Main.vue";
import "regenerator-runtime/runtime";
import { core, routingScope, modal, modalCompilerVueConfigExtension } from "./root";
import PersonIncidentsComponent from "./vueComponent/request/PersonIncidents.vue";
import CompanyRecordComponent from "./vueComponent/request/CompanyRecord.vue";
import PersonBoundComponent from "./vueComponent/request/PersonBound.vue";
import PersonDataModalComponent from "./vueComponent/request/PersonDataModal.vue";
import { createPinia } from "pinia";

const globalProperties: any = {
    routingScope: routingScope,
    openIncidentsModal: (personId: string, searchId: string | null) => {
        const data = { id: personId, searchId: searchId, mode: "records" };
        modal.open(PersonDataModalComponent, { data: data, dialog: true });
    },
    openBoundModal: (personId: string, searchId: string | null) => {
        const data = { id: personId, searchId: searchId, mode: "bindings" };
        modal.open(PersonDataModalComponent, { data: data, dialog: true });
    },
    openCompanyRecordsModal: (companyId: string, searchId: string | null) => {
        const data = { id: companyId, searchId: searchId };
        modal.open(CompanyRecordComponent, { data: data, dialog: true });
    },
};

const pinia = createPinia();

core.start().then(async () => {
    let app = createApp(Main);
    Object.keys(globalProperties).forEach((_) => {
        app.config.globalProperties[_] = globalProperties[_];
        modalCompilerVueConfigExtension[_] = globalProperties[_];
    });
    sessionStorage.clear();
    app.use(pinia);
    app.mount("#app");

    if (!("Notification" in window)) {
        alert("Браузер не поддерживает уведомления. Пожалуйста, обновите браузер.");
    } else if (Notification.permission === "granted") {
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                alert("Вы отключили уведомления. Включите их в настройках браузера для корректной работы приложения.");
            }
        });
    }

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register(window.origin + "/service-worker.js").catch(function (error) {
            console.error("Ошибка при регистрации Service Worker:", error);
        });
    }

    const swRegistration = await navigator.serviceWorker.register(window.origin + "/service-worker.js");
    const pushManager = swRegistration.pushManager;

    const vapidKey = await fetch("/im/notification/vapid", {
        headers: {
            "Content-Type": "application/json",
            Secret: core.api.secret,
        },
    }).then((response) => response.json());

    // Подписка на уведомления с использованием VAPID public key
    const subscription = await pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidKey.result.publicKey,
    });

    // Преобразование подписки в формат JSON
    const subscriptionJson = subscription.toJSON();

    // Формируем тело запроса для отправки на сервер
    const requestBody = JSON.stringify({
        endpoint: subscriptionJson.endpoint,
        p256dh: subscriptionJson.keys.p256dh,
        auth: subscriptionJson.keys.auth,
    });

    // Отправка подписки на сервер
    await fetch("im/notification", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Secret: core.api.secret,
        },
        body: requestBody,
    });
});
