import { DirectiveBinding, ObjectDirective } from "vue";
import { Util } from "../util";

const PasteFileDirective: ObjectDirective<HTMLElement, (files: File[]) => void> = {
    mounted(el: any, binding: DirectiveBinding<(files: File[]) => void>) {
        const id = Util.generateRandomId();
        el.setAttribute("paste-file-id", id);

        const pasteHandler = (event: ClipboardEvent) => {
            if (!event.clipboardData) {
                return;
            }
            const files: File[] = [];
            const items = event.clipboardData.items;
            for (let item of items) {
                if (item.kind !== "file") {
                    continue;
                }
                const file = item.getAsFile();
                if (file) {
                    files.push(file);
                }
            }
            if (files.length > 0 && binding.value instanceof Function) {
                binding.value(files);
            }
        };

        document.addEventListener("paste", pasteHandler);

        el.dataset.pasteHandlerId = `pasteHandler__${id}`;
        el[`pasteHandler__${id}`] = pasteHandler;
    },
    unmounted(el: any) {
        const id = el.dataset.pasteHandlerId;
        if (id && el[id]) {
            document.removeEventListener("paste", el[id]);
            delete el[id];
        }
    }
};

export default PasteFileDirective;
