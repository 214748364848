import { defineStore } from "pinia";
import { core } from "../../root";
import { MessengerApi } from "./messengerService";
import { MessageAlert } from "./sounds/MessageAlert";

const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
const host = window.location.host;

export const useMessengerStore = defineStore("messenger", {
    state: () => ({
        websocket: new WebSocket(`${protocol}${host}/im?secret=${core.api.secret}`) as WebSocket,
        chats: new Map() as MessengerChats,
        users: new Map() as MessengerUsers,
        currentUser: null as MessengerUser | null,
        currentChat: null as (MessengerGroupChat | MessengerPrivateChat) | null,
        isSyncing: true as boolean,
        connectionError: false as boolean,
        mode: "home" as "home" | "chat",
        initialLoading: true as boolean,
        isChatOpen: false as boolean,
    }),
    actions: {
        addChat(chat: MessengerGroupChat | MessengerPrivateChat) {
            if (this.chats.has(chat.id)) return;
            this.chats.set(chat.id, chat);
        },
        addUser(user: MessengerUser) {
            if (this.users.has(user.userId)) return;
            this.users.set(user.userId, user);
        },
        selectChat(chat: MessengerGroupChat | MessengerPrivateChat) {
            this.currentChat = chat;
            this.mode = "chat";
        },
        setCurrentUser(user: MessengerUser) {
            this.currentUser = user;
        },
        setSyncStatus(value: boolean) {
            this.isSyncing = value;
        },
        addMessage(message: MessengerMessage) {
            if (this.currentChat) {
                if (this.currentChat.messages.find((m) => m.id === message.id)) return;
                this.currentChat.messages.push(message);
                this.chats.get(this.currentChat.id).lastMessageDate = message.timestamp;
            } else {
                const audio = new Audio("data:audio/wav;base64," + MessageAlert);
                audio.play();
                this.chats.get(message.chatId).unreadMessagesCount += 1;
                this.chats.get(message.chatId).lastMessageDate = message.timestamp;
            }
        },
        exitChat() {
            this.currentChat = null;
            this.mode = "home";
        },
        setConnectionError(value: boolean) {
            this.connectionError = value;
        },
        reconnectWebSocket() {
            if (this.websocket) {
                this.websocket.close();
            }

            this.websocket = new WebSocket(`${protocol}${host}/im?secret=${core.api.secret}`) as WebSocket;

            this.websocket.onerror = (error) => {
                console.error("WebSocket error:", error);
                this.connectionError = true;
                setTimeout(() => {
                    useMessengerStore().reconnectWebSocket();
                }, 5000);
            };

            this.websocket.onopen = async () => {
                await MessengerApi.openedConnection();
                this.connectionError = false;
            };
        },
        setInitialLoading(value: boolean) {
            this.initialLoading = value;
        },
        readMessage(chatId: string, messageId: string, userId: string) {
            const chat = this.chats.get(chatId);
            if (chat) {
                const message = chat.messages.find((m) => m.id === messageId);
                if (message) {
                    if (message.readers === null) {
                        message.readers = new Array();
                    }
                    message.readers.push(userId);
                }
            }
        },
        async startChatting(id: string) {
            await MessengerApi.createPrivateChat(id);
            this.isChatOpen = true;
            localStorage.setItem("isChatOpen", JSON.stringify(this.isChatOpen));
        },
        setScroll(scroll?: string | null) {
            this.currentChat.scroll = scroll || null;
        },
    },
});
