import { DirectiveBinding, ObjectDirective } from "vue";

const FileInputDirective: ObjectDirective<HTMLElement, (files: File | FileList | null) => void> = {
    mounted(element: HTMLElement, binding: DirectiveBinding<(files: File | FileList | null) => void>) {
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "file";
        hiddenInput.style.display = "none";

        function onChange(event: Event) {
            const target = event.target as HTMLInputElement;
            if (!(binding.value instanceof Function)) {
                return;
            }
            if (binding.modifiers.multiple) {
                binding.value(target.files);
            } else {
                binding.value(target.files && target.files.length > 0 ? target.files[0] : null);
            }
        }

        function appendInput() {
            const newInputElement = hiddenInput.cloneNode(true) as HTMLInputElement;
            newInputElement.onchange = onChange;
            newInputElement.onclick = (event: MouseEvent) => event.stopPropagation();

            if (binding.modifiers.multiple) {
                newInputElement.multiple = true;
            }

            element.appendChild(newInputElement);
        }

        function onElementClick() {
            const input = element.querySelector('input[type="file"]') as HTMLInputElement;
            if (input) {
                input.click();
            }
        }

        element.addEventListener("click", onElementClick);

        appendInput();
    },
    unmounted(element: HTMLElement, binding: any) {
        element.removeEventListener("click", binding.instance.onElementClick);
        const inputs = element.querySelectorAll('input[type="file"]');
        inputs.forEach(input => input.remove());
    }
};

export default FileInputDirective;
