import { ObjectDirective } from "vue";

const handler = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
        const el = event.target as HTMLInputElement;
        el.value = null;
        el.dispatchEvent(new Event("input"));
    }
};

const ClearInput: ObjectDirective<HTMLInputElement> = {
    mounted(el: HTMLInputElement, binding) {
        el.addEventListener("keydown", handler);
    },
    beforeUnmount(el: HTMLInputElement) {
        el.removeEventListener("keydown", handler);
    }
};

export default ClearInput;
