import { DirectiveBinding, ObjectDirective } from "vue";

const DragDropDirective: ObjectDirective<HTMLElement, (files: FileList | File | null) => void> = {
    mounted(element: HTMLElement, binding: DirectiveBinding<(files: FileList | File | null) => void>) {
        element.classList.add("drag-area");

        element.addEventListener("dragover", (event: DragEvent) => {
            event.preventDefault();
            element.classList.add("drag-hover");
        });

        element.addEventListener("dragleave", (event: DragEvent) => {
            event.preventDefault();
            element.classList.remove("drag-hover");
        });

        element.addEventListener("drop", (event: DragEvent) => {
            event.preventDefault();
            element.classList.remove("drag-hover");
            const files = event.dataTransfer?.files;
            if (files && binding.value instanceof Function) {
                if (binding.modifiers.multiple) {
                    binding.value(files);
                } else {
                    binding.value(files.length === 0 ? null : files[0]);
                }
            }
        });
    },
    unmounted(el: any) {
        const id = el.getAttribute("paste-file-id");
        if (id) {
            const handler = el[`pasteHandler__${id}`] as EventListener;
            if (handler) {
                document.removeEventListener("paste", handler);
                delete el[`pasteHandler__${id}`];
            }
        }
    }
};

export default DragDropDirective;
