import { Api } from "../../core";
import { core } from "../../root";
import { useMessengerStore } from "./messengerStore";

export class MessengerApi {
    public static async openedConnection() {
        useMessengerStore().setInitialLoading(true);
        try {
            await MessengerApi.getUsers().then((response) => {
                response.users.forEach((user) => {
                    useMessengerStore().addUser(user);
                });
                useMessengerStore().setCurrentUser(response.currentUser);
            });

            await MessengerApi.getChats().then((response) => {
                response.chats.forEach((chat: any) => {
                    const _users: Map<string, MessengerUser> = new Map();
                    const _messages: Array<MessengerMessage> = new Array();
                    for (const user of chat.users) {
                        _users.set(user.userId, user);
                    }
                    for (const message of chat.messages) {
                        _messages.push(message);
                    }
                    const _chat: MessengerGroupChat | MessengerPrivateChat = {
                        id: chat.id,
                        isPrivate: chat.isPrivate,
                        name: chat.name,
                        users: _users,
                        messages: _messages,
                        unreadMessagesCount: chat.unreadMessagesCount,
                        lastMessageDate: chat.lastMessageDate,
                        scroll: null,
                    };
                    useMessengerStore().addChat(_chat);
                });
            });
        } catch (error) {
            console.error("Error:", error);
        } finally {
            useMessengerStore().setInitialLoading(false);
        }
        useMessengerStore().setSyncStatus(false);
        useMessengerStore().setConnectionError(false);
    }
    public static async getUsers(): Promise<{ users: Array<MessengerUser>; currentUser: MessengerUser }> {
        return await this.restRequest("/im/user", "GET");
    }
    public static async getChats(): Promise<any> {
        return await this.restRequest("/im/chat", "GET");
    }
    public static async createPrivateChat(userId: string): Promise<void> {
        await this.restRequest("/im/chat?private=true", "POST", { users: [userId] }).then((response) => {
            const _ = response.chat;
            const _users: Map<string, MessengerUser> = new Map();
            const _messages: Array<MessengerMessage> = new Array();
            for (const user of _.users) {
                _users.set(user.userId, user);
            }
            for (const message of _.messages) {
                _messages.push(message);
            }
            const chat: MessengerGroupChat | MessengerPrivateChat = {
                id: _.id,
                isPrivate: _.isPrivate,
                name: _.name,
                users: _users,
                messages: _messages,
                unreadMessagesCount: _.unreadMessagesCount,
                lastMessageDate: _.lastMessageDate,
                scroll: null,
            };
            useMessengerStore().addChat(chat);
            useMessengerStore().selectChat(chat);
        });
    }
    public static async loadChatHistory(chatId: string, scroll?: string, prev?: string): Promise<void> {
        const query = Api.buildQueryString({ scroll: scroll ? scroll : null, limit: 100, prev: prev });
        await this.restRequest(`/im/chat/${chatId}/message?${query}`, "GET").then((response) => {
            useMessengerStore().setScroll(response.session);
            response.messages.forEach((message: MessengerMessage) => useMessengerStore().addMessage(message));
        });
    }
    public static async sendMessage(chatId: string, text: string): Promise<void> {
        await this.restRequest(`/im/chat/${chatId}/message`, "POST", { content: text }).then((response) => {
            useMessengerStore().addMessage(response.message);
        });
    }
    public static async readMessage(chatId: string, messageId: string): Promise<void> {
        await this.restRequest(`/im/chat/${chatId}/message/${messageId}/read`, "POST").then((response) => {
            for (const msg of response.messages) {
                useMessengerStore().readMessage(msg.chatId, msg.messageId, msg.userId);
            }
            useMessengerStore().chats.get(chatId).unreadMessagesCount = response.chat.unreadMessagesCount;
        });
    }
    private static async restRequest(url: string, method: string, value?: any): Promise<any> {
        try {
            const response = await fetch(url, {
                method: method,
                body: value ? JSON.stringify(value) : undefined,
                headers: {
                    "Content-Type": "application/json",
                    "X-API-Secret": core.api.secret,
                    secret: core.api.secret,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`);
            }

            const json = await response.json();
            return json.result;
        } catch (error) {
            console.error(`Error in restRequest: ${error}`);
            throw error;
        }
    }
    private static wsRequest(value: object) {
        useMessengerStore().websocket.send(JSON.stringify(value));
    }
}

export const messengerService = () => {
    const handleSocketMessage = (event: MessageEvent) => {
        try {
            const response = JSON.parse(event.data);
            // Handle events
            if (response.event === "new_message") {
                const _ = response.data.message;
                useMessengerStore().addMessage(_);
            }
            if (response.event === "read_message") {
                const _ = response.data;
                useMessengerStore().readMessage(_.chatId, _.messageId, _.userId);
            }
            if (response.event === "new_chat") {
                const _ = response.data.chat;
                const _users: Map<string, MessengerUser> = new Map();
                const _messages: Array<MessengerMessage> = new Array();
                for (const user of _.users) {
                    _users.set(user.userId, user);
                }
                for (const message of _.messages) {
                    _messages.push(message);
                }
                const chat: MessengerGroupChat | MessengerPrivateChat = {
                    id: _.id,
                    isPrivate: _.isPrivate,
                    name: _.name,
                    users: _users,
                    messages: _messages,
                    unreadMessagesCount: _.unreadMessagesCount,
                    lastMessageDate: _.lastMessageDate,
                    scroll: null,
                };
                useMessengerStore().addChat(chat);
            }
        } catch (error) {
            console.error("Ошибка при обработке сообщения:", error);
        }
    };

    useMessengerStore().websocket.onopen = async () => {
        await MessengerApi.openedConnection();
    };

    useMessengerStore().websocket.onerror = (error) => {
        console.error("WebSocket error:", error);
        useMessengerStore().setConnectionError(true);
        setTimeout(() => {
            useMessengerStore().reconnectWebSocket();
        }, 5000);
    };

    useMessengerStore().websocket.onmessage = handleSocketMessage;
};
